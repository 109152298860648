import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'jeremy_wang',
        name: 'Jeremy Wang',
        designation: 'Founder',
        location: 'Austin, TX, USA', 
        description: 'Solo-entrepreneur behind Cloud-AI.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/Jeremywang1990'
            },
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/Jeremyw_90'
            },
            {
                icon: <FiInstagram />,
                url: 'https://www.instagram.com/jerw_austin/'
            },
        ]
       
    },
    // {
    //     image: 'team-02',
    //     name: 'Afsana Nila',
    //     designation: 'App Developer',
    //     location: 'Bangladesh', 
    //     description: 'Yes, I am a product designer. I have a passion for product design.',
    //     socialNetwork: [
    //         {
    //             icon: <FiFacebook />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiTwitter />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiLinkedin />,
    //             url: '#'
    //         },
    //     ]
      
    // },
    // {
    //     image: 'team-03',
    //     name: 'Afanan Sifa',
    //     designation: 'Accounts Manager',
    //     location: 'Poland', 
    //     description: 'Yes, I am a product designer. I have a passion for product design.',
    //     socialNetwork: [
    //         {
    //             icon: <FiFacebook />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiTwitter />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiInstagram />,
    //             url: '#'
    //         },
    //     ]
        
    // },
]

const TeamTwo = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`} target="_blank" rel="noopener noreferrer">{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
